import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import noop from '../../../utils/noop';
import styles from './Switch.style';

const Switch = (props) => {
  const { isChecked, onChange, manageState, id } = props;
  const [isSelected, setSelected] = useState(isChecked);
  const handleOnChange = () => {
    if (manageState) {
      setSelected(!isSelected);
    }
    onChange({
      isSelected: !isSelected,
    });
  };
  useEffect(() => {
    setSelected(isChecked);
  }, [props]);
  return (
    <label htmlFor={`chk-${id}`} className="switch">
      <input id={`chk-${id}`} type="checkbox" checked={isSelected} onChange={handleOnChange} />
      <span className="slider round" />
      <style jsx>{styles}</style>
    </label>
  );
};

Switch.defaultProps = {
  isChecked: false,
  onChange: noop,
  manageState: true,
};

Switch.propTypes = {
  id: PropTypes.string.isRequired,
  isChecked: PropTypes.bool,
  onChange: PropTypes.func,
  manageState: PropTypes.bool,
};

export default Switch;

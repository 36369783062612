import getConfig from 'next/config';
import apiConfig from '../config/api/apiConfig';

import constants from '../config/constants';
import { formatSearchTerm, getPageType, getSignal, getStore, makeRequest } from '../utils/SponsoredHelper';
import WebStorage from '../utils/WebStorage';

const nextConfig = getConfig();
const publicRuntimeConfig = (nextConfig && nextConfig.publicRuntimeConfig) || {};

const sponsoredUrlBase = publicRuntimeConfig.SPONSORED_ENDPOINT;
const { PRODUCTS } = constants.AD_TYPES_FOR_VAS;

const sponsoredClickService = async ({ adInfo, mPos, enabled, categoryName, platform, tenant, store, adType }) => {
  if (`${enabled}` === 'false') return;
  const cookie = WebStorage.getItem({ name: 'mabayaSession', fromPersistence: true }) || '';
  const vasStore = getStore(store);
  const data = {
    pageType: getPageType(),
    adType: adType || PRODUCTS,
    context: {
      userIdentifier: cookie,
      adIdentifier: adInfo,
      ...(mPos !== undefined && { slot: mPos }),
      signal: getSignal(categoryName),
      ...(vasStore && { store: vasStore }),
    },
  };

  const url = apiConfig.sponsoredClickUrl(sponsoredUrlBase);
  await makeRequest(url, data, platform, tenant);
};

const sponsoredImpressionService = async ({ platform, adInfo, mPos, enabled, tenant }) => {
  if (`${enabled}` === 'false') return;

  const cookie = WebStorage.getItem({ name: 'mabayaSession', fromPersistence: true }) || '';

  const data = {
    context: {
      ...(cookie && { userIdentifier: cookie }),
      adIdentifier: adInfo,
      ...(mPos !== undefined && { slot: mPos }),
    },
  };

  const url = apiConfig.sponsoredImpressionUrl(sponsoredUrlBase);
  await makeRequest(url, data, platform, tenant);
};

const sponsoredVisitService = async ({ referrerUrl = '', sku, enabled, isPDP = false, platform, tenant }) => {
  if (`${enabled}` === 'false') return;

  const isSLP = !!referrerUrl.split('Ntt=')[1];
  const referrerSignal = isSLP ? formatSearchTerm(referrerUrl) : '';
  if (!isPDP && !referrerSignal) {
    return;
  }

  const cookie = (isPDP && WebStorage.getItem({ name: 'mabayaSession', fromPersistence: true })) || '';

  const data = {
    context: {
      skuId: sku,
      searchTerm: referrerSignal,
      ...(cookie && { userIdentifier: cookie }),
    },
  };

  const url = apiConfig.sponsoredVisitUrl(sponsoredUrlBase);
  await makeRequest(url, data, platform, tenant);
};

export { sponsoredClickService, sponsoredImpressionService, sponsoredVisitService };

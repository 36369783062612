import { LoginModal } from '@digital-retail/falabella-ui-cross-components';
import { SponsoredBrandWrapper, SponsoredProductBanner } from '@digital-retail/sponsored-provider-library';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ActionBar from '../../components/ActionBar/ActionBar';
import { resetDigitalData } from '../../components/Facets/Common/digitalData';
import MediaComponent from '../../components/Media/MediaComponent';
import MyListAlert from '../../components/MyLists/MyListsAlert/MyListAlert';
import Pagination from '../../components/Pagination/Pagination';
import PaginationDetail from '../../components/Pagination/PaginationDetail/PaginationDetail';
import Pod from '../../components/Pod/Pod';
import ProductOverlay from '../../components/ProductOverlay/ProductOverlay';
import Dropdown from '../../components/ui/Dropdown/Dropdown';
import apiConfig from '../../config/api/apiConfig';
import constants from '../../config/constants';
import { track } from '../../external_dependencies/analytic';
import { addToCart as DDAddToCart } from '../../external_dependencies/digitalData/cart';
import { withApplicationContext } from '../../utils/ApplicationContext';
import { withCart } from '../../utils/CartContext';
import { withConfig } from '../../utils/ConfigurationContext';
import { withLabels } from '../../utils/LabelsContext';
import _ from '../../utils/LodashImports';
import { withMyLists } from '../../utils/MyListsContext';
import ProductContext from '../../utils/ProductContext';
import {
  navigateWithUrl,
  navigateWithUrlForBrand,
  navigateWithUrlForCategory,
  navigateWithUrlForCollection,
  navigateWithUrlForSeller,
} from '../../utils/RouteHandler';
import SkipSSR from '../../utils/SkipSSR';
import { withUser } from '../../utils/UserContext';
import WebStorage from '../../utils/WebStorage';
import WithRouter from '../../utils/WithRouter';
import httpService from '../../utils/httpService';
import noop from '../../utils/noop';
import SearchResultsStyles from './SearchResults.style';
import {
  getFuntionAnalyticsSponsored,
  getHandleBannerActionForSponsorDisplay,
  getHandleBrandActionForSponsoredBrand,
  getIsSponsoredDisplayEnabled,
  getPageId,
  getShouldShowSponsoredBrand,
} from './utils';

const PodWithoutSSR = SkipSSR(Pod);

const SearchResults = ({
  layout,
  products,
  pagination,
  sortOptions,
  appCtx,
  pageName,
  labels,
  cart,
  setShowFacets,
  setStopScroll,
  config,
  refreshUserData,
  collectionId,
  myLists,
  relatedKeywordsUI,
}) => {
  const { tenant, setBusy, deviceType, regionCode, store } = appCtx;
  const { MABAYA_TOKEN, MABAYA_EVENTS_ENABLED, FEATURE_TOGGLE_SPONSORED_VAS_SERVICE } = config;
  const id = WithRouter('id');
  const name = WithRouter('name');
  const Ntt = WithRouter('Ntt');
  const xlpId = pageName === 'search' ? Ntt : id;
  const providedParamforBanner = getPageId(pageName);
  const isMobile = deviceType === 'mobile';

  const ssrPodCount = isMobile ? 6 : 12;
  const [productOverlayContext, setProductOverlayContext] = useState({});
  const [isAddToCartInProgress, setIsAddToCartInProgress] = useState(false);
  const [hasAddToCartError, setHasAddToCartError] = useState(false);
  const [isAddToCartSuccessful, setIsAddToCartSuccessful] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [show401Error, setShow401Error] = useState('');
  const [hasImpressionBeenSent, setHasImpressionBeenSent] = useState(false);

  const isSponsoredDisplayEnabled = getIsSponsoredDisplayEnabled(appCtx);

  const { addedLists } = myLists || {};
  const catalystBaseUrl = _.get(appCtx, 'siteConfig.endpoints.CATALYST_AUTH_BASEURL', 'https://www.falabella.com');
  const excludeGscForAddToCart = _.get(appCtx, 'siteConfig.textDictionary.EXCLUDE_GSC_CATEGORY_ID_FOR_ADD_TO_CART', '');

  const excludeAddToCartGscSet = new Set(excludeGscForAddToCart.split(','));
  const toggleLoginForm = () => {
    cart.setShowLoginForm(!cart.showLoginForm);
  };
  const onClose = () => {
    toggleLoginForm();
  };
  const onUserLogin = () => {
    refreshUserData();
  };

  useEffect(() => {
    const sessionId = WebStorage.getItem({
      name: 'mabayaSession',
      fromPersistence: true,
    });
    if (!(sessionId && sessionId !== 'undefined' && sessionId !== 'false')) {
      const val = window.btoa(Date.now());
      WebStorage.setItem({
        name: 'mabayaSession',
        value: val,
        shouldPersist: true,
      });
    }
  }, []);

  const handlerSortOption = ({ url }) => {
    setStopScroll(false);
    if (pageName === 'category') {
      navigateWithUrlForCategory({
        site: tenant,
        onRouteChange: () => {
          setBusy(true);
        },
        url,
        categoryId: id,
        categoryName: name,
        store,
        regionCode,
      });
    } else if (pageName === 'collection') {
      navigateWithUrlForCollection({
        site: tenant,
        onRouteChange: () => {
          setBusy(true);
        },
        url,
        collectionId: id,
        store,
        regionCode,
      });
    } else if (pageName === 'seller') {
      navigateWithUrlForSeller({
        regionCode,
        site: tenant,
        onRouteChange: () => {
          setBusy(true);
        },
        url,
        sellerName: id,
        store,
      });
    } else if (pageName === 'brand') {
      navigateWithUrlForBrand({
        regionCode,
        site: tenant,
        onRouteChange: () => {
          setBusy(true);
        },
        url,
        brandName: id,
        store,
      });
    } else {
      navigateWithUrl({
        site: tenant,
        onRouteChange: () => {
          setBusy(true);
        },
        url,
        store,
        regionCode,
      });
    }
    resetDigitalData();
  };

  const onModalCloseHandler = () => {
    setProductOverlayContext({});
    setHasAddToCartError(false);
    setIsAddToCartSuccessful(false);
    if (deviceType !== 'desktop') {
      setShowFacets(true);
    }
  };

  const setShowModalHandler = (product, selectedVariantId) => {
    setStopScroll(true);
    const productCopy = { ...product, selectedVariantId };
    productCopy.attributes = {
      viewTemplate: product.viewTemplate,
    };
    setProductOverlayContext(productCopy);
    cart.setShowCart(true);
    if (deviceType !== 'desktop') {
      setShowFacets(false);
    }
  };

  const addProductToCart = async (cartContext, product) => {
    setStopScroll(true);
    setShow401Error(false);
    setSelectedProductId(cartContext.productId);
    const productCopy = { ...product };
    productCopy.currentVariant = cartContext;
    productCopy.isSLP = true;
    productCopy.attributes = {
      viewTemplate: product.viewTemplate,
    };

    const params = {};
    params.pgid = appCtx.priceGroupId;
    params.pid = appCtx.politicalId;
    params.zones = encodeURIComponent(appCtx.zones);

    const getProductLiteDetailsUrl = apiConfig.getProductLiteDetailsUrl({
      regionCode: appCtx.regionCode,
      rest: {
        variantId: cartContext.skuId,
        merchantCategoryId: product.merchantCategoryId,
        brandName: product.brand,
        ...params,
      },
    });

    const getProductLiteDetailsUrlPromise = httpService(appCtx).get(getProductLiteDetailsUrl);
    const isInternational = _.get(product, 'availability.internationalShipping', '');
    const addToCartPromise = cart.addProduct({
      appCtx,
      replaceCartItems: true,
      products: [
        {
          ...cartContext,
          isInternational: !!isInternational,
        },
      ],
      cb: (data) => {
        DDAddToCart(data.cartLines[0], 1);
      },
    });
    setIsAddToCartInProgress(true);
    Promise.all([getProductLiteDetailsUrlPromise, addToCartPromise])
      .then(([productLiteDetails, addToCartErrorResponse]) => {
        const {
          data: { data = {} },
        } = productLiteDetails;
        if (data.stockUnits) {
          productCopy.stockUnits = {};
          data.stockUnits.forEach((stockUnit) => {
            productCopy.stockUnits[stockUnit.variantId] = data.maxOnCartAmount;
          });
          productCopy.maxOnCartAmount = data.maxOnCartAmount;
          productCopy.layoutType = data.layoutType;
          productCopy.guideSymbol = data.guideSymbol;
          setProductOverlayContext({});
          if (!addToCartErrorResponse) {
            track('addToCart');
            setHasAddToCartError(false);
            setIsAddToCartSuccessful(true);
            cart.setShowCart(true);
            setProductOverlayContext(productCopy);
          } else {
            setIsAddToCartSuccessful(false);
            setHasAddToCartError(true);
            cart.setShowCart(true);
            setProductOverlayContext(productCopy);
          }
          setIsAddToCartInProgress(false);
        } else {
          setIsAddToCartInProgress(false);
          setHasAddToCartError(true);
        }
        const errorCode = _.get(addToCartErrorResponse, '[0].code', '');
        if (errorCode === constants.ATC_SESSION_EXPIRED_CODE) {
          setShow401Error(true);
        }
        if (deviceType !== 'desktop') {
          setShowFacets(false);
        }
      })
      .catch(() => {
        cart.setShowCart(true);
        setProductOverlayContext(productCopy);
        setIsAddToCartSuccessful(false);
        setHasAddToCartError(true);
        setIsAddToCartInProgress(false);
      });
  };

  const sponsoredClick = (productData, order, storeValue) => {
    const isSponsored = _.get(productData, 'isSponsored', false);
    const adInfo = _.get(productData, 'mabaya_ad_info', '') || _.get(productData, 'adIdentifier', '');
    const sku = _.get(productData, 'skuId', '');
    return (action) => {
      const isSponsoredService = FEATURE_TOGGLE_SPONSORED_VAS_SERVICE || false;

      const { sponsoredClickService, sponsoredImpressionService, sponsoredVisitService } =
        getFuntionAnalyticsSponsored(isSponsoredService);

      const params = {
        mabayaToken: MABAYA_TOKEN,
        enabled: MABAYA_EVENTS_ENABLED,
        platform: deviceType,
        referrerUrl: window.location.href,
        sku,
        regionCode,
        adInfo,
        mPos: order + 1,
        categoryName: name,
        tenant,
        store: storeValue,
      };

      if (isSponsored) {
        if (action === 'click') {
          sponsoredClickService(params);
        }
        if (action === 'impression') {
          sponsoredImpressionService(params);
        }
      }
      if (action === 'visit') {
        sponsoredVisitService(params);
      }
    };
  };

  const handleBannerAction = getHandleBannerActionForSponsorDisplay({
    appCtx,
    config,
    categoryName: name,
    mPos: 9,
    hasImpressionBeenSent,
    setHasImpressionBeenSent,
  });

  const handleBrandAction = getHandleBrandActionForSponsoredBrand({
    appCtx,
    config,
    categoryName: name,
    hasImpressionBeenSent,
    setHasImpressionBeenSent,
  });
  const handlePodClick = (productData, position, totalProducts) => (setDDXLPOnly) => {
    const productType = _.get(productData, 'isSponsored', false) ? 'Sponsored' : 'Normal';
    const pageType = pageName === 'search' ? 'SLP' : 'PLP';
    const multipurposeBadges = _.get(productData, 'multipurposeBadges', []).map((badge) => badge.label);
    const meatStickers = _.get(productData, 'meatStickers', []).map((badge) => badge.label);
    const discountBadge = _.get(productData, 'discountBadge.label', '');
    const badges = [...multipurposeBadges, ...meatStickers];
    if (discountBadge) badges.push(discountBadge);
    const eventToDigitalData = new CustomEvent('DDXLPPodClick', {
      bubbles: true,
      detail: {
        payload: `${position} | ${totalProducts} | ${pageType} | ${productType} | ${badges.join(',') || 'none'}`,
        setDDXLPOnly,
      },
    });
    window.dispatchEvent(eventToDigitalData);
  };

  return (
    <div className="search-results" id="testId-searchResults">
      {!_.isEmpty(productOverlayContext) && (
        <ProductContext.Provider data={productOverlayContext}>
          <ProductOverlay
            labels={labels}
            onModalCloseHandler={onModalCloseHandler}
            appCtx={appCtx}
            addProductToCart={addProductToCart}
            isAddToCartInProgress={isAddToCartInProgress}
            cart={cart}
            hasAddToCartError={hasAddToCartError}
            isAddToCartSuccessful={isAddToCartSuccessful}
            show401Error={show401Error}
          />
        </ProductContext.Provider>
      )}
      <div className="search-results--actionbar" id="testId-searchResults-actionBar">
        <ActionBar
          left={
            sortOptions.length !== 0 && (
              <Dropdown
                label={labels.ORDER_BY_DESKTOP}
                options={sortOptions}
                tenant={tenant}
                onOptionSelected={handlerSortOption}
                isPrimaryTheme
              />
            )
          }
          right={
            pagination.data.total !== 0 && (
              <Pagination
                pageName={pageName}
                currentPage={pagination.data.currentPage}
                resultsPerPage={pagination.data.perPage}
                totalResults={pagination.data.count >= 9600 ? 9600 : pagination.data.count}
                getPaginatedUrl={pagination.getPaginatedUrl}
                section="top"
                tenant={appCtx.tenant}
                setBusy={appCtx.setBusy}
                setStopScroll={setStopScroll}
                store={store}
              />
            )
          }
        />
      </div>
      <div className="container-my-lists-alert">
        <MyListAlert deviceType={deviceType} addedLists={addedLists} page="xlp" />
      </div>
      <div key={pagination.data.currentPage} className="search-results--products" id="testId-searchResults-products">
        {products.map((product, index) => {
          const productCopy = { ...product };
          const shouldShowSponsoredBrand = getShouldShowSponsoredBrand({ appCtx, pageName, name, deviceType, index });

          if (shouldShowSponsoredBrand) {
            return (
              <>
                <SponsoredBrandWrapper
                  key={pagination.data.currentPage}
                  providedParam={providedParamforBanner}
                  isSearchPage={false}
                  categoryName={name}
                  onBannerClick={handleBrandAction}
                  containerStyle={{
                    margin: '0 0 10px 10px',
                    borderRadius: '12px',
                    color: '#4A4A4A',
                    ...(isMobile ? { marginRight: '10px' } : {}),
                  }}
                />
                <div style={{ display: 'none' }} />
                <Pod
                  layout={layout}
                  product={productCopy}
                  deviceType={deviceType}
                  lazyLoadImage={
                    index >=
                    (deviceType === 'desktop'
                      ? constants.SKIP_LAZY_LOAD_IMAGE_COUNT_DESKTOP
                      : constants.SKIP_LAZY_LOAD_IMAGE_COUNT_MOBILE)
                  }
                  setShowModalHandler={setShowModalHandler}
                  addProductToCart={addProductToCart}
                  isAddToCartInProgress={isAddToCartInProgress}
                  isSelected={selectedProductId === product.productId}
                  labels={labels}
                  sponsoredClick={sponsoredClick(product, index, store)}
                  collectionId={collectionId}
                  handlePodClick={handlePodClick(product, index + 1, products.length)}
                  isFirstPod={index === 0}
                  excludeAddToCartGscSet={excludeAddToCartGscSet}
                />
              </>
            );
          }

          return index < ssrPodCount ? (
            <Pod
              layout={layout}
              product={productCopy}
              deviceType={deviceType}
              lazyLoadImage={
                index >=
                (deviceType === 'desktop'
                  ? constants.SKIP_LAZY_LOAD_IMAGE_COUNT_DESKTOP
                  : constants.SKIP_LAZY_LOAD_IMAGE_COUNT_MOBILE)
              }
              setShowModalHandler={setShowModalHandler}
              addProductToCart={addProductToCart}
              isAddToCartInProgress={isAddToCartInProgress}
              isSelected={selectedProductId === product.productId}
              labels={labels}
              sponsoredClick={sponsoredClick(product, index, store)}
              collectionId={collectionId}
              handlePodClick={handlePodClick(product, index + 1, products.length)}
              isFirstPod={index === 0}
              excludeAddToCartGscSet={excludeAddToCartGscSet}
            />
          ) : (
            <PodWithoutSSR
              layout={layout}
              product={productCopy}
              deviceType={deviceType}
              lazyLoadImage={
                index >=
                (deviceType === 'desktop'
                  ? constants.SKIP_LAZY_LOAD_IMAGE_COUNT_DESKTOP
                  : constants.SKIP_LAZY_LOAD_IMAGE_COUNT_MOBILE)
              }
              setShowModalHandler={setShowModalHandler}
              addProductToCart={addProductToCart}
              isAddToCartInProgress={isAddToCartInProgress}
              isSelected={selectedProductId === product.productId}
              labels={labels}
              sponsoredClick={sponsoredClick(product, index, store)}
              collectionId={collectionId}
              handlePodClick={handlePodClick(product, index + 1, products.length)}
              isFirstPod={index === 0}
              excludeAddToCartGscSet={excludeAddToCartGscSet}
            />
          );
        })}
      </div>

      {relatedKeywordsUI}
      <div className="search-results--actionbar_bottom" id="testId-searchResults-actionBar-bottom">
        <div className="paginationDetail">
          <MediaComponent from="desktop" to="desktop">
            <PaginationDetail
              totalResults={pagination.data.count}
              resultsPerPage={pagination.data.perPage}
              currentPage={pagination.data.currentPage}
              ofTextLabel={labels.OF}
              resultsTextLabel={labels.RESULTS}
            />
          </MediaComponent>
        </div>

        {pagination.data.total !== 0 && (
          <Pagination
            currentPage={pagination.data.currentPage}
            resultsPerPage={pagination.data.perPage}
            totalResults={
              pagination.data.count >= constants.PAGINATION_TOTAL_RESULTS_LIMIT
                ? constants.PAGINATION_TOTAL_RESULTS_LIMIT
                : pagination.data.count
            }
            getPaginatedUrl={pagination.getPaginatedUrl}
            section="bottom"
            tenant={appCtx.tenant}
            setBusy={appCtx.setBusy}
            pageName={pageName}
            setStopScroll={setStopScroll}
            store={store}
          />
        )}
      </div>

      {isSponsoredDisplayEnabled && (
        <div className="bannner-styles">
          <SponsoredProductBanner
            providedParam={providedParamforBanner}
            providedId={xlpId}
            onBannerClick={handleBannerAction}
            categoryName={name}
            isVertical={false}
          />
        </div>
      )}
      <LoginModal
        id="testId-atc-xlp-searchResults"
        catalystBaseUrl={catalystBaseUrl}
        onClose={onClose}
        onUserLogin={onUserLogin}
        tenant={appCtx.regionCode}
        showLoginForm={cart.showLoginForm}
      />
      <style jsx>{SearchResultsStyles}</style>
    </div>
  );
};

SearchResults.propTypes = {
  products: PropTypes.arrayOf(PropTypes.object),
  sortOptions: PropTypes.array.isRequired,
  pagination: PropTypes.shape({
    data: PropTypes.object,
    onPageSelection: PropTypes.func,
    getPaginatedUrl: PropTypes.func,
  }).isRequired,
  appCtx: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  labels: PropTypes.object,
  layout: PropTypes.object,
  pageName: PropTypes.string,
  cart: PropTypes.object.isRequired,
  setShowFacets: PropTypes.func.isRequired,
  refreshUserData: PropTypes.func.isRequired,
  setStopScroll: PropTypes.func,
  collectionId: PropTypes.string,
  myLists: PropTypes.object.isRequired,
  relatedKeywordsUI: PropTypes.object,
};

SearchResults.defaultProps = {
  products: [],
  labels: {},
  layout: {
    desktop: {
      view: '4_GRID',
    },
    mobile: {
      view: '2_GRID',
    },
  },
  pageName: 'search',
  setStopScroll: noop,
  collectionId: '',
  relatedKeywordsUI: null,
};

export default withApplicationContext(withLabels(withConfig(withUser(withCart(withMyLists(SearchResults))))));
export { SearchResults };

import css from 'styled-jsx/css';
import { colors, typography, zIndex } from '../../config/styles';
import { svgUrl } from '../../utils/staticUrls';

const heart = svgUrl('heart.svg');
const heartFill = svgUrl('heart-fill.svg');
const style = css`
  .container-my-lists {
    float: right;

    .csicon-my-list {
      background-image: url(${heart});
      background-repeat: no-repeat;
      background-size: contain;
      height: 27px;
      width: 29px;
      display: inline-block;
      vertical-align: middle;
      &.active {
        background-image: url(${heartFill});
        background-position: center;
      }
    }
    &.xlp-container {
      &.one-column {
        float: none;
        position: absolute;
        top: 30px;
        right: 33%;
        z-index: ${zIndex.xlp.myListsIcon};
        button {
          padding: 12px;
        }
        &.mobile {
          top: 26px;
          right: 3px;
        }
      }
      .csicon-my-list {
        height: 18px;
        width: 19px;
      }
    }
  }
  .my-lists-modal-container {
    .my-lists-modal-header {
      padding-bottom: 16px;
      @mixin mobileToTablet {
        padding-bottom: 0;
      }
      span {
        font-size: ${typography.xl2};
        line-height: 24px;
        margin-left: 16px;
        vertical-align: middle;
        font-weight: 700;
        color: ${colors.gray.shade15};
      }
      p {
        font-size: 14px;
        line-height: 17px;
        color: ${colors.gray.shade15};
        margin-top: 16px;
      }
      .csicon-my-list {
        height: 18px;
        width: 19px;
      }
    }
    .my-lists-button {
      margin-top: 23px;
      display: flex;
      justify-content: center;
      .box-button {
        max-width: 240px;
        width: 100%;
        display: flex;
      }
    }
  }
`;
export default style;

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import constants from '../../../../../../config/constants';
import noop from '../../../../../../utils/noop';
import { ShippingBoxButtonStyles } from './ShippingBoxButton.style';

const ShippingBoxButton = (props) => {
  const { id, isChecked, onChange, manageState, hasTimeConstraint, title } = props;
  const [isSelected, setSelected] = useState(isChecked);
  const handleOnChange = () => {
    if (manageState) {
      setSelected(!isSelected);
    }
    onChange({
      isSelected: !isSelected,
    });
  };
  useEffect(() => {
    setSelected(isChecked);
  }, [props]);

  function truncateText(str, charLimit, addEllipsis) {
    if (!str || str.length < charLimit) return str;
    return str.substr(0, charLimit).trim() + (addEllipsis === true ? '...' : '');
  }
  return (
    <>
      <div className={`shipping-button ${isSelected ? 'selected' : ''}`}>
        <button id={id} type="button" onClick={handleOnChange}>
          <div
            className={`shipping-button-text ${hasTimeConstraint ? 'green-text' : ''} ${isSelected ? 'selected' : ''}`}
          >
            {truncateText(title, constants.FACETS.VALUE_TEXT_CHARCOUNT, true)}
          </div>
        </button>
      </div>
      <style jsx>{ShippingBoxButtonStyles}</style>
    </>
  );
};

ShippingBoxButton.defaultProps = {
  id: '',
  onChange: noop,
  isChecked: false,
  title: '',
  manageState: true,
  hasTimeConstraint: false,
};

ShippingBoxButton.propTypes = {
  id: PropTypes.string,
  isChecked: PropTypes.bool,
  onChange: PropTypes.func,
  manageState: PropTypes.bool,
  hasTimeConstraint: PropTypes.bool,
  title: PropTypes.string,
};

export { ShippingBoxButton };

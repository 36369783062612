import css from 'styled-jsx/css';
import { colors, typography } from '../../../config/styles';

const ChipStyles = css`
  .chip {
    font-size: ${typography.xs2};
    color: ${colors.black.shade8};
    background-color: ${colors.green.shade7};
    border: 1px solid ${colors.green.shade1};
    border-radius: 4px;
    display: inline-flex;
    align-items: center;
    margin: 0 10px 10px 0;
    padding: 4px 6px;
    cursor: pointer;
    font-family: inherit;
  }
  i {
    color: ${colors.gray.shade5};
    font-size: 8px;
    font-weight: 700;
    margin-left: 8px;
    outline: none;
    display: flex;
  }
  .chip:hover i {
    color: ${colors.black.shade1};
  }
  /* stylelint-disable font-family-no-missing-generic-family-keyword */
  .mkp {
    border: 2px solid var(--primary-color);
    border-radius: 3px;
    background: ${colors.white.shade1};
    color: ${colors.black.shade1};
    height: 20px;
    @mixin mobileToTablet {
      border: 1px solid var(--grayscale-gray-background-100, #eee);
      border-radius: 10px;
      padding: 5px 10px;
      height: 30px;
      color: var(--Black, #333);
      font-family: Lato;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      flex-shrink: 0;
    }
  }
  .csicon-danger {
    @mixin mobileToTablet {
      font-size: ${typography.xs};
      color: ${colors.black.shade1};
      font-weight: lighter;
    }
  }
  /* stylelint-disable length-zero-no-unit */
  .star {
    font-size: 18px;
    margin: -4px -4px -2px 0px;
  }
`;

export default ChipStyles;

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import noop from '../../../utils/noop';
import { checkboxStyles } from './Checkbox.style';

const Checkbox = (props) => {
  const {
    isChecked,
    onChange,
    id,
    children,
    variant,
    disableClick,
    manageState,
    inConnect,
    type,
    selectedBorderClass,
    normalBorderClass,
    inFloorCalculator,
    isShippingFacet,
    disabled,
    isPrimaryTheme,
    checkBoxIcon,
  } = props;
  const [isSelected, setSelected] = useState(isChecked);

  const renderCheckIcon = () => {
    if (isSelected) {
      return (
        <>
          <i
            id={`${id}-icon`}
            className={`csicon-${type} ${inFloorCalculator ? 'in-floor-calc' : ''} ${checkBoxIcon} ${
              isShippingFacet ? 'shipping' : ''
            }`}
          />
          <style jsx>{checkboxStyles}</style>
        </>
      );
    }
    return null;
  };
  const handleOnChange = () => {
    if (manageState) {
      setSelected(!isSelected);
    }
    onChange({
      isSelected: !isSelected,
    });
  };
  const titleClass = inConnect ? 'connect-title' : 'title';
  const renderCheckBox = () => {
    if (isShippingFacet) {
      return (
        <>
          {children}
          <div className="shipping-label">
            <input id={id} type="checkbox" checked={isSelected} onChange={disableClick ? noop : handleOnChange} />
            <span
              id={`${id}-span`}
              className={`${titleClass} shipping ${
                isShippingFacet && 'hasTimeConstraint'
              } ${variant}${selectedBorderClass}${normalBorderClass} ${inFloorCalculator ? 'in-floor-calc' : ''}`}
            />
            {renderCheckIcon()}
          </div>
          <style jsx>{checkboxStyles}</style>
        </>
      );
    }
    return (
      <>
        <input id={id} type="checkbox" checked={isSelected} onChange={disableClick ? noop : handleOnChange} />
        <span
          className={`${titleClass} ${
            isShippingFacet && 'hasTimeConstraint'
          } ${variant}${selectedBorderClass}${normalBorderClass} ${inFloorCalculator ? 'in-floor-calc' : ''}`}
        >
          {renderCheckIcon()}
          {children}
        </span>
        <style jsx>{checkboxStyles}</style>
      </>
    );
  };
  useEffect(() => {
    setSelected(isChecked);
  }, [props]);

  return (
    <label
      id={`${id}-wrapper`}
      className={`checkbox ${disabled ? ' disabled' : ''} ${isShippingFacet ? 'shipping' : ''}`}
      htmlFor={id}
    >
      {renderCheckBox()}
      <style jsx>{`
         {
          --checkbox-color: ${isPrimaryTheme ? 'var(--primary-color)' : 'var(--secondary-color)'};
          --checkbox-rebranded: ${isPrimaryTheme
            ? 'var(--primary-checkbox-rebranded)'
            : 'var(--secondary-checkbox-rebranded)'};
          --checkbox-round-rebranded: ${isPrimaryTheme
            ? 'var(--primary-round-checkbox-rebranded)'
            : 'var(--secondary-round-checkbox-rebranded)'};
        }
      `}</style>
      <style jsx>{checkboxStyles}</style>
    </label>
  );
};

Checkbox.defaultProps = {
  children: null,
  onChange: noop,
  isChecked: false,
  variant: 'secondary',
  disableClick: false,
  manageState: true,
  inConnect: false,
  type: 'succes',
  selectedBorderClass: '',
  normalBorderClass: '',
  inFloorCalculator: false,
  isShippingFacet: false,
  disabled: false,
  isPrimaryTheme: false,
  checkBoxIcon: '',
};

Checkbox.propTypes = {
  children: PropTypes.node,
  isChecked: PropTypes.bool,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  variant: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'services']),
  disableClick: PropTypes.bool,
  manageState: PropTypes.bool,
  inConnect: PropTypes.bool,
  type: PropTypes.oneOf('succes', 'mkp-succes', 'mkp-bundle-succes'),
  selectedBorderClass: PropTypes.oneOf(' mkp-border', ' mkp-border-bundle', ''),
  normalBorderClass: PropTypes.oneOf(' mkp-normal-border', ''),
  inFloorCalculator: PropTypes.bool,
  isShippingFacet: PropTypes.bool,
  disabled: PropTypes.bool,
  isPrimaryTheme: PropTypes.bool,
  checkBoxIcon: PropTypes.string,
};

export default Checkbox;

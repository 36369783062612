import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import apiConfig from '../../../config/api/apiConfig';
import constants from '../../../config/constants';
import { withApplicationContext } from '../../../utils/ApplicationContext';
import { withConfig } from '../../../utils/ConfigurationContext';
import { withLabels } from '../../../utils/LabelsContext';
import _ from '../../../utils/LodashImports';
import {
  navigateWithUrl,
  navigateWithUrlForBrand,
  navigateWithUrlForCategory,
  navigateWithUrlForCollection,
  navigateWithUrlForSeller,
} from '../../../utils/RouteHandler';
import SkipSSR from '../../../utils/SkipSSR';
import WithRouter from '../../../utils/WithRouter';
import WithToggle from '../../../utils/WithToggle';
import { isWindow } from '../../../utils/deviceChecker';
import { useOnScroll } from '../../../utils/hooks/UseOnScroll';
import httpService from '../../../utils/httpService';
import { toggleBodyClass } from '../../../utils/jsUtils';
import noop from '../../../utils/noop';
import Dialog from '../../ui/Dialog/Dialog';
import MobileLinkMenu from '../../ui/MobileLinkMenu/MobileLinkMenu';
import MobileMenu from '../../ui/MobileMenu/MobileMenu';
import getFacetsData from '../Common/getFacetsData';
import { facetClick } from '../FacetEvents';
import FacetWithSwitch from '../FacetsWithSwitch/FacetWithSwitch/FacetWithSwitch';
import FacetsWithSwitch from '../FacetsWithSwitch/FacetsWithSwitch';
import { facetsMobileGlobalStyle, facetsMobileStyles } from './FacetsMobile.style';
import FacetsMobileFooter from './FacetsMobileFooter';

const DialogWithoutSSR = SkipSSR(Dialog);

const FacetsMobile = ({
  config,
  data: initialFacetsData,
  dataMethods,
  title,
  mobileTitle,
  appCtx,
  toggleState,
  dropDownLabel,
  sortOptions,
  labels,
  availabilityFacets: initialAvailabilityFacets,
  onHideMenu,
  selectedURLs: originalSelection,
  selectedURLsForAvailability,
  pageName,
  onFacetChangeHandler,
  getUpdatedFacets,
  appliedFacets: initialAppliedFacets,
  pagination,
  handleScrollToTopProductList,
}) => {
  const ref = useRef();
  const { tenant, setBusy, regionCode, store } = appCtx;
  const mobileAvailabilityTitle = labels.FILTERS_AVAILABILITY_TITLE_MOBILE;
  const searchInFacetsLabel = labels.SEARCH_IN_FACETS_LABEL;
  const priceRangeErrorLabel = labels.ENTER_VALID_PRICE_ERROR_LABEL;
  const priceRangeNoResultsLabel = labels.PRICE_NO_RESULTS_ERROR_LABEL;

  const id = WithRouter('id');
  const name = WithRouter('name');
  const Ntt = WithRouter('Ntt');
  const xlpId = pageName === 'search' ? Ntt : id;

  const isFacetWithSwitchVisible = _.get(appCtx, 'siteConfig.toggles.isFacetWithSwitchVisible', false);
  const isEventSwitchVisible = _.get(appCtx, 'siteConfig.toggles.isEventToggleVisible', false);
  const handlerSortOption = ({ url }) => {
    if (pageName === 'category') {
      navigateWithUrlForCategory({
        site: tenant,
        onRouteChange: () => {
          setBusy(true);
        },
        url,
        categoryId: id,
        categoryName: name,
        store,
        regionCode,
      });
    } else if (pageName === 'collection') {
      navigateWithUrlForCollection({
        site: tenant,
        onRouteChange: () => {
          setBusy(true);
        },
        url,
        collectionId: id,
        store,
        regionCode,
      });
    } else if (pageName === 'seller') {
      navigateWithUrlForSeller({
        regionCode,
        site: tenant,
        onRouteChange: () => {
          setBusy(true);
        },
        url,
        sellerName: id,
        store,
      });
    } else if (pageName === 'brand') {
      navigateWithUrlForBrand({
        regionCode,
        site: tenant,
        onRouteChange: () => {
          setBusy(true);
        },
        url,
        brandName: id,
        store,
      });
    } else {
      navigateWithUrl({
        site: tenant,
        onRouteChange: () => {
          setBusy(true);
        },
        url,
        store,
        regionCode,
      });
    }
  };
  const [appliedFacets, setAppliedFacets] = useState(initialAppliedFacets);
  const [digitalData, setDigitalData] = useState([]);
  const { baseFacetsURL, changeURL } = dataMethods;
  const initPaginationCount = _.get(pagination, 'data.count', undefined);
  const [paginationCount, setCount] = useState(initPaginationCount);
  const showMenuFacets = () => toggleState.setVisible(true);
  const hideMenuFacets = () => toggleState.setVisible(false);

  const [isMenuActive, setMenuActive] = useState(false);
  const [isSortByDialogVisible, setSortyByDialogVisibility] = useState(false);

  const [showAvailability, setShowAvailabilityFacets] = useState(false);

  const [selectedURLs, setSelectedURLs] = useState(originalSelection);
  const [selectedURLsForAvailabilityFacets, setSelectedURLsForAvailabilityFacets] =
    useState(selectedURLsForAvailability);
  const [selectedMenu, setSelectedMenu] = useState({});
  const [selectedFacet, setSelectedFacet] = useState('');

  const [facetCount, setFacetCount] = useState(0);
  const [availabilityFacetCount, setAvailabilityFacetCount] = useState(0);
  const [facets, setFacets] = useState(initialFacetsData);
  const [availabilityFacets, setAvailabilityFacets] = useState(initialAvailabilityFacets);
  const [finalSelectedUrl, setFinalSelectedUrl] = useState('');
  const [headerTitle, setHeaderTitle] = useState(title);
  const [selectedFacetsMenu, setSelectedFactesInsideMenu] = useState([]);
  const showMenuAvailabilityFacets = () => setShowAvailabilityFacets(true);
  const hideMenuAvailabilityFacets = () => setShowAvailabilityFacets(false);

  useEffect(() => {
    onHideMenu(hideMenuFacets);
  }, []);

  useEffect(() => {
    const panelState = showAvailability || toggleState.visible || isSortByDialogVisible;
    toggleBodyClass(panelState, 'FacetMobilePanel--open');
  }, [showAvailability, toggleState.visible, isSortByDialogVisible]);

  useEffect(() => {
    if (Object.keys(selectedMenu).length === 0) {
      setHeaderTitle(title);
      setMenuActive(false);
    }
  }, [selectedMenu]);

  const updateCount = () => {
    const reducer = (acc, facet) => {
      let total = acc + facet.values.filter(({ selected }) => selected).length;
      if (facet.meta && facet.meta[0].selected) {
        total += 1;
      }
      return total;
    };
    const facetsCount = initialFacetsData.reduce(reducer, 0);
    const availabilityCount = initialAvailabilityFacets.reduce(reducer, 0);

    setFacetCount(facetsCount);
    setAvailabilityFacetCount(availabilityCount);
  };

  const filterFacetUrls = (initialUrls) => {
    const urls = initialUrls;
    if (urls[constants.FACETS.PRICE_SEARCH_KEY] && urls[constants.FACETS.PRICE_SEARCH_KEY][0] === '::') {
      delete urls[constants.FACETS.PRICE_SEARCH_KEY];
    }

    const facetURLArray = [];
    Object.keys(urls).forEach((key) => {
      const value = urls[key].sort();
      const encodedKey = encodeURIComponent(key);
      const encodedValue = encodeURIComponent(value.join('::'));
      if (value.length) facetURLArray.push(`&${encodedKey}=${encodedValue}`);
    });
    return facetURLArray;
  };

  const setSelectedFacets = () => {
    const initSelectedURLs = {};
    const initSelectedURLsForAvailabilityFacets = {};

    initialFacetsData.forEach((facet) => {
      facet.values.forEach((value) => {
        const { selected, facetType, facetValue } = value;
        if (selected) {
          initSelectedURLs[facetType] = [...(initSelectedURLs[facetType] || []), facetValue];
        }
      });
    });

    initialAvailabilityFacets.forEach((facet) => {
      facet.values.forEach((value) => {
        const { selected, facetType, facetValue } = value;
        if (selected) {
          initSelectedURLsForAvailabilityFacets[facetType] = [
            ...(initSelectedURLsForAvailabilityFacets[facetType] || []),
            facetValue,
          ];
        }
      });
    });

    const urls = {
      ...initSelectedURLs,
      ...initSelectedURLsForAvailabilityFacets,
    };
    const facetURLArray = filterFacetUrls(urls);

    setSelectedURLs(initSelectedURLs);
    setSelectedURLsForAvailabilityFacets(initSelectedURLsForAvailabilityFacets);
    setFinalSelectedUrl([...facetURLArray].sort().join(''));
  };

  useEffect(() => {
    setFacets(initialFacetsData);
    setAvailabilityFacets(initialAvailabilityFacets);
    setAppliedFacets(initialAppliedFacets);
    setCount(initPaginationCount);
    updateCount();
    setSelectedFacets();
  }, [initialFacetsData, initialAvailabilityFacets]);
  useEffect(() => {
    handleScrollToTopProductList(selectedURLs);
  }, [selectedURLs]);

  useEffect(() => {
    if (selectedFacetsMenu.length) {
      setSelectedFactesInsideMenu([]);
    }
  }, [selectedFacet, initialFacetsData, initialAvailabilityFacets]);

  useEffect(() => {
    if (digitalData.length > 0) {
      const eventToDigitalData = new CustomEvent('DDXLPMobileTabInteraction', {
        bubbles: true,
        detail: { payload: digitalData.join('|') },
      });
      window.dispatchEvent(eventToDigitalData);
    }
  }, [digitalData]);

  const makeFacetCall = async (facetUrl) => {
    const url = facetUrl;
    const zoneParams = `pid=${encodeURIComponent(appCtx.politicalId)}&zones=${encodeURIComponent(
      appCtx.zones
    )}&pgid=${encodeURIComponent(appCtx.priceGroupId)}`;
    const storeParam = store ? `&store=${store}` : '';

    appCtx.setBusy(true);

    const getFacetValuesUrl = apiConfig.getSelectedFacetValues({
      base: isWindow() ? config.API_HOST : config.CLUSTER_API_HOST,
      siteId: regionCode,
      params: `${url}&${zoneParams}&xlpId=${encodeURIComponent(xlpId)}&xlpName=${pageName}${storeParam}`,
    });
    const updatedFacets = await httpService(appCtx).get(getFacetValuesUrl);

    if (_.get(updatedFacets, 'data.data.availabilityFacets', undefined)) {
      setAvailabilityFacets(updatedFacets.data.data.availabilityFacets);
    }

    if (_.get(updatedFacets, 'data.data.pagination.count', undefined)) {
      const updatePagination = updatedFacets.data.data.pagination.count;
      setCount(updatePagination);
    }
    const updatedAppliedFacets = _.get(updatedFacets, 'data.data.appliedFacets', undefined);
    if (appliedFacets) {
      setAppliedFacets(updatedAppliedFacets);
    }
    if (_.get(updatedFacets, 'data.data.facets', undefined)) {
      setFacets(updatedFacets.data.data.facets);
    }
    appCtx.setBusy(false);
    setFinalSelectedUrl(facetUrl);
  };

  const onFacetApply = () => {
    const url = finalSelectedUrl;
    const urls = [];
    if (baseFacetsURL) {
      urls.push(baseFacetsURL);
    }
    if (url) {
      const facetURL = url.split('&').filter((u) => {
        return !!u && !u.match(/facetSelected/g);
      });
      if (facetURL.length > 0) {
        urls.push('facetSelected=true', facetURL.join('&'));
      }
    }
    changeURL([...urls].sort().join('&'));
  };

  const digitalDataSetter = (type, interacted = false) => {
    const userInteractedUpdates = JSON.parse(JSON.stringify(digitalData));
    userInteractedUpdates[0] = 1;
    userInteractedUpdates[1] = 0;
    if (type) {
      userInteractedUpdates[2] = type;
    }
    if (interacted) {
      userInteractedUpdates[1] = 1;
    }
    setDigitalData(userInteractedUpdates);
  };

  const onFacetChange = async (obj) => {
    facetClick();
    const { url } = obj;
    const urls = [];
    if (url) {
      const facetURL = url.split('&').filter((u) => !u.match(/facetSelected/g));
      if (facetURL.length > 0) {
        urls.push(facetURL.join('&'));
      }
    }
    await makeFacetCall(urls.join('&'));
    digitalDataSetter('', true);
  };

  const facetData = facets.map(
    getFacetsData({
      groupType: '',
      tenant,
      onFacetChange,
      baseFacetsURL,
      changeURL,
      priceRangeErrorLabel,
      priceRangeNoResultsLabel,
      searchInFacetsLabel,
      pageName,
      categoryId: id,
      hideMenuFacets,
      appCtx,
      isMobileFacetUI: true,
      selectedFacetsMenu,
      selectedFacet,
      getUpdatedFacets,
    })
  );

  const onFacetClose = (hideMenu) => () => {
    const initFacets = initialFacetsData;
    initFacets.forEach((facet, index) => {
      initFacets[index].state = false;
    });
    setFacets(initFacets);
    setAvailabilityFacets(initialAvailabilityFacets);
    setAppliedFacets(initialAppliedFacets);
    setCount(initPaginationCount);
    updateCount();
    setSelectedFacets();
    hideMenu();
  };

  const onFacetChangeForEvent = (obj) => {
    const { url } = obj;
    const urls = [];
    if (baseFacetsURL) {
      urls.push(baseFacetsURL);
    }
    if (url) {
      const facetURL = url.split('&').filter((u) => !u.match(/facetSelected/g));
      if (facetURL.length > 0) {
        urls.push('facetSelected=true', facetURL.join('&'));
      }
    }
    changeURL(urls.join('&'));
    onFacetChangeHandler();
  };

  const eventData = facetData.filter((d) => constants.EVENT_REGEX.test(d.type));
  const availabilityFacetsData = availabilityFacets.map(
    getFacetsData({
      groupType: 'availability',
      tenant,
      onFacetChange,
      baseFacetsURL,
      changeURL,
      priceRangeErrorLabel,
      priceRangeNoResultsLabel,
      searchInFacetsLabel,
      pageName,
      categoryId: id,
      hideMenuFacets,
      appCtx,
    })
  );

  const availabilitySwitchFacetsData = facets
    .filter((f) => constants.FACETS_WITH_SWITCH_REGEX.test(f.name))
    .map(
      getFacetsData({
        groupType: 'facetsWithSwitch',
        tenant,
        onFacetChange,
        baseFacetsURL,
        changeURL,
        priceRangeErrorLabel,
        priceRangeNoResultsLabel,
        searchInFacetsLabel,
        pageName,
        categoryId: id,
        hideMenuFacets,
        appCtx,
      })
    );

  const cleanData = (isGroupedFacets) => {
    let cleanedFacetsURLs = {};
    if (!isGroupedFacets) {
      cleanedFacetsURLs = selectedURLsForAvailabilityFacets;
    } else {
      cleanedFacetsURLs = selectedURLs;
    }
    const urls = cleanedFacetsURLs;
    const facetURLArray = filterFacetUrls(urls);
    if (facetURLArray.length > 0) {
      facetURLArray.push('&facetSelected=true');
    }
    changeURL(baseFacetsURL + [...facetURLArray].sort().join(''), false);
  };

  const facetMobileDialogAction = (obj) => {
    const isGroupedFacets = Object.prototype.hasOwnProperty.call(obj, 'isGroupedFacets') ? obj.isGroupedFacets : false;
    switch (obj.action) {
      case 'APPLY':
        onFacetApply();
        break;

      case 'CLEAN':
        cleanData(isGroupedFacets);
        break;
      default:
        break;
    }
  };

  const handleAccordianClick = async (obj) => {
    setSelectedFacet(obj);
    facetData.forEach((facet, index) => {
      if (facet.name === obj) {
        facetData[index].state = !facetData[index].state;
      } else {
        facetData[index].state = false;
      }
    });
    setFacets(facetData);
    digitalDataSetter('', true);
  };

  const dialogData = (showDialog, facetDataForDialog, hideMenu, isGroupedFacets, pane, flag) => {
    const onClean = () => {
      facetMobileDialogAction({
        action: 'CLEAN',
        isGroupedFacets,
      });
    };

    const hideActiveMenu = () => {
      facetMobileDialogAction({
        action: 'BACK',
      });
    };

    const body = () => (
      <>
        {isFacetWithSwitchVisible && flag && <FacetsWithSwitch items={availabilitySwitchFacetsData} />}
        <MobileMenu
          pane={pane}
          isGroupedFacets={isGroupedFacets}
          data={
            isFacetWithSwitchVisible
              ? facetDataForDialog.filter((d) => {
                  if (isEventSwitchVisible) {
                    return !constants.FREE_SHIPPING_REGEX.test(d.name) && !constants.EVENT_REGEX.test(d.type);
                  }
                  return !constants.FREE_SHIPPING_REGEX.test(d.name);
                })
              : facetDataForDialog.filter((d) => {
                  if (isEventSwitchVisible) {
                    return !constants.EVENT_REGEX.test(d.type);
                  }
                  return true;
                })
          }
          appCtx={appCtx}
          facetTitleClass="mkp-facet-title"
          handleAccordianClick={handleAccordianClick}
          baseFacetsURL={baseFacetsURL}
          appliedFacets={appliedFacets}
          makeFacetCall={makeFacetCall}
          digitalDataSetter={digitalDataSetter}
          facets={facets}
        />
      </>
    );

    const footer = () => (
      <FacetsMobileFooter
        isGroupedFacets={isGroupedFacets}
        facetMobileDialogAction={facetMobileDialogAction}
        hideMenu={hideMenu}
        cleanSelectedFacets={onClean}
        readyButtonType="mkp-secondary"
        readyButtonSize="small primary-theme"
        count={paginationCount}
        appliedFacets={appliedFacets}
        digitalDataSetter={digitalDataSetter}
      />
    );

    return {
      isMenuActive,
      animationDirection: 'rightToLeft',
      showDialog,
      header: {
        headerClass: 'mkp-header',
        title: isGroupedFacets ? mobileAvailabilityTitle : headerTitle,
        onClose: onFacetClose(hideMenu),
        onClean,
        hideActiveMenu,
      },
      body,
      footer,
    };
  };

  const handleStickyFacetHeader = () => {
    if (!ref.current) return;
    const topOffset = ref.current.getBoundingClientRect().top;
    if (topOffset <= 0) {
      toggleBodyClass(true, 'stickyHeader');
    } else {
      toggleBodyClass(false, 'stickyHeader');
    }
  };

  useOnScroll({
    handler: _.debounce(handleStickyFacetHeader, 10),
  });

  const body = () => (
    <MobileLinkMenu
      title={labels.CHOOSE_OPTION}
      links={sortOptions}
      onSelection={(link) => {
        handlerSortOption(link);
        setSortyByDialogVisibility(false);
        onFacetChangeHandler();
        digitalDataSetter(dropDownLabel, true);
      }}
    />
  );

  return (
    <div className="facetsMobile" ref={ref} id="testId-facets-mobile-container">
      <div className="facetmobile-container">
        <div className="facetmobile-filters">
          {(availabilityFacetsData.length > 0 || availabilitySwitchFacetsData.length > 0) && (
            <div className="facetmobile--items">
              <button
                className="btn-with-icon"
                type="button"
                onClick={() => {
                  showMenuAvailabilityFacets();
                  digitalDataSetter(labels.FILTERS_AVAILABILITY_TITLE_MOBILE);
                }}
              >
                {availabilityFacetCount > 0 ? (
                  <span className="mkp-count">{availabilityFacetCount}</span>
                ) : (
                  <i className="csicon-disponibilidad-mkp" />
                )}
                <span className="mkp">{labels.FILTERS_AVAILABILITY_TITLE_MOBILE}</span>
              </button>
            </div>
          )}

          <div className="facetmobile--items">
            <button
              className="btn-with-icon"
              type="button"
              onClick={() => {
                setSelectedMenu({});
                showMenuFacets();
                digitalDataSetter(mobileTitle);
              }}
            >
              {facetCount > 0 ? <span className="mkp-count">{facetCount}</span> : <i className="csicon-filters" />}

              <span>{mobileTitle}</span>
            </button>
          </div>

          <div className="facetmobile--items">
            {sortOptions.length !== 0 && (
              <button
                className="btn-with-icon"
                type="button"
                id="testId-FacetsMobile-button-sort"
                onClick={() => {
                  setSortyByDialogVisibility(true);
                  digitalDataSetter(dropDownLabel);
                }}
              >
                <i className="csicon-icon-order" />
                <span>{dropDownLabel}</span>
              </button>
            )}
          </div>
        </div>
        {isEventSwitchVisible && (
          <FacetWithSwitch
            item={eventData[0] ? eventData[0] : {}}
            onFacetChange={onFacetChangeForEvent}
            type="event"
            appCtx={appCtx}
            isMobileFacetUI
          />
        )}
      </div>
      {sortOptions.length > 0 && (
        <DialogWithoutSSR
          animationDirection="rightToLeft"
          showDialog={isSortByDialogVisible}
          header={{
            title: `${labels.ORDER_BY_DESKTOP}`,
            onClose: () => {
              setSortyByDialogVisibility(false);
            },
            onClean: noop,
            hideActiveMenu: noop,
          }}
          body={body}
        />
      )}
      {<DialogWithoutSSR {...dialogData(toggleState.visible, facetData, hideMenuFacets, false, 'multiple', null)} />}
      {(availabilityFacetsData.length > 0 || availabilitySwitchFacetsData.length > 0) && (
        <DialogWithoutSSR
          {...dialogData(
            showAvailability,
            [...availabilityFacetsData, ...availabilitySwitchFacetsData],
            hideMenuAvailabilityFacets,
            true,
            'single',
            true
          )}
        />
      )}
      <style jsx>{facetsMobileStyles}</style>
      <style jsx>{facetsMobileGlobalStyle}</style>
    </div>
  );
};

FacetsMobile.defaultProps = {
  onHideMenu: noop,
  pageName: 'search',
  onFacetChangeHandler: noop,
};

FacetsMobile.propTypes = {
  toggleState: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  mobileTitle: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.objectOf).isRequired,
  sortOptions: PropTypes.array.isRequired,
  dropDownLabel: PropTypes.string.isRequired,
  availabilityFacets: PropTypes.array.isRequired,
  appCtx: PropTypes.object.isRequired,
  onHideMenu: PropTypes.func,
  selectedURLs: PropTypes.object.isRequired,
  dataMethods: PropTypes.object.isRequired,
  labels: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  pageName: PropTypes.string,
  selectedURLsForAvailability: PropTypes.object.isRequired,
  onFacetChangeHandler: PropTypes.func,
  getUpdatedFacets: PropTypes.func.isRequired,
  appliedFacets: PropTypes.array.isRequired,
  pagination: PropTypes.object.isRequired,
  handleScrollToTopProductList: PropTypes.func.isRequired,
};

export { FacetsMobile, FacetsMobileFooter };

export default WithToggle(withApplicationContext(withLabels(withConfig(FacetsMobile))));

import css from 'styled-jsx/css';

const styles = css`
  .switch {
    position: relative;
    display: inline-block;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #bbb;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    width: 43.2px;
    height: 15.6px;
  }

  .slider::before {
    position: absolute;
    content: '';
    height: 24px;
    width: 24px;
    left: -1px;
    bottom: -4px;
    background-color: #f8f8f8;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border: 1px solid #cacaca;
    box-shadow: -1px 2px 4px rgba(129, 124, 124, 0.25);
  }

  input:checked + .slider::before {
    -webkit-transform: translateX(23px);
    -ms-transform: translateX(23px);
    transform: translateX(23px);
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
  }

  .slider.round {
    border-radius: 34px;
  }

  .slider.round::before {
    border-radius: 50%;
  }
`;

export default styles;
